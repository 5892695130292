import React from 'react' 
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledUnderline = styled.div`
  width: 50px;
  height: 4px;
  background: #2c5282;
  margin-top: 50px;
  margin-bottom: 22px;
`

const PageAccessibilitySection = () => (  
  <div className='bg-gray-200'>
    <div className='lg:w-10/12 mx-auto py-20 sm:py-6 sm:px-4 md:py-12 md:px-8'>
      <h2 className='font-display text-gray-900 text-3xl sm:text-2xl leading-snug mb-6'>Interpacific Asset Management Accessibility Statement</h2>

      <StyledUnderline></StyledUnderline>

      <div className='w-9/12 sm:w-full'>

        <p className='text-sm text-gray-900 mb-6'>Updated: December 2019.</p>

        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>General</h3>
        <p className='text-xl text-gray-900 mb-6'>Interpacific Asset Management strives to ensure that its services are accessible to people with disabilities. Interpacific Asset Management has invested a significant amount of resources to help ensure that its website is made easier to use and more accessible for people with disabilities, with the strong belief that every person has the right to live with dignity, equality, comfort and independence.</p>

        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Accessibility on Interpacific Asset Management</h3>
        <p className='text-xl text-gray-900 mb-6'>Interpacific Asset Management makes available the <a href="https://UserWay.org" className='font-bold underline' target='_blank' rel='noopener noreferrer' alt="Free Website Accessibility Widget" title="Free Website Accessibility Widget">UserWay Website Accessibility Widget</a> that is powered by a dedicated accessibility server. The software allows Interpacific Asset Management to improve its compliance with the Web Content Accessibility Guidelines (WCAG 2.1).</p>

        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Enabling the Accessibility Menu</h3>
        <p className='text-xl text-gray-900 mb-6'>The Interpacific Asset Management accessibility menu can be enabled by clicking the accessibility menu icon that appears on the corner of the page. After triggering the accessibility menu, please wait a moment for the accessibility menu to load in its entirety.</p>

        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Disclaimer</h3>
        <p className='text-xl text-gray-900 mb-4'>Interpacific Asset Management continues its efforts to constantly improve the accessibility of its site and services in the belief that it is our collective moral obligation to allow seamless, accessible and unhindered use also for those of us with disabilities.</p>
        <p className='text-xl text-gray-900 mb-6'>Despite our efforts to make all pages and content on Interpacific Asset Management fully accessible, some content may not have yet been fully adapted to the strictest accessibility standards. This may be a result of not having found or identified the most appropriate technological solution.</p>

        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Here For You</h3>
        <p className='text-xl text-gray-900 mb-6'>If you are experiencing difficulty with any content on interpacificmgmt.com or require assistance with any part of our site, please contact us during normal business hours as detailed below and we will be happy to assist.</p>

        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Contact Us</h3>
        <p className='text-xl text-gray-900 mb-6'>If you wish to report an accessibility issue, have any questions or need assistance, please <Link to='/contact' className='font-bold underline'>click here</Link> to contact us.</p>
      </div>
    </div>
  </div>      
)

export default PageAccessibilitySection