import React from "react"
import PageContainer from "../containers/PageContainer"
import HeadData from "../data/HeadData"
import PageHeaderImageBg from '../headers/PageHeaderImageBg'
import PageHeaderText from "../headers/PageHeaderText"
import HeaderNav from "../navigation/HeaderNav"
import HomeAffiliationSection from "../sections/HomeAffiliationSection"
import CTASection from "../sections/CTASection"
import PageAccessibilitySection from "../sections/PageAccessibilitySection"

export default () => (
  <PageContainer>
    <HeadData 
      title='Accessibility Statement' 
      description='Interpacific Asset Management strives to ensure that its services are accessible to people with disabilities.' 
    />

    <PageHeaderImageBg>
      <HeaderNav />
      <PageHeaderText
        title='Accessibility'
      />
    </PageHeaderImageBg>

    <PageAccessibilitySection />

    <HomeAffiliationSection />

    <CTASection />
  </PageContainer>
)
